import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import 'bootstrap-icons/font/bootstrap-icons.css'
import MySnackBar from './components/Utils/MySnackBar.vue'
loadFonts()

createApp(App)
  .use(router)
  .use(store)
  .use(vuetify)
  .component('MySnackBar', MySnackBar)
  .mount('#app')
