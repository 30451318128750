/* eslint-disable */

export default {

    state: {
        routeName: "",
       
    },
    getters: {
        getCurrentRouteName(state) {
            return state.routeName;
        },
        
    },
    actions: {

        setCurrentRouteName({ commit }, diabetic){
            commit('SET_ROUTENAME', diabetic);
        },
       
    },
    mutations: {
        SET_ROUTENAME(state, data) {
            state.routeName = data;
        },
       

    }
}