/* eslint-disable */

export default {

    state: {
        valid: true,
    },
    getters: {
        isInputValididty(state) {
            return state.valid;
        },
    },
    actions: {

        setInputValidity({ commit }, payload) {
            commit('SET_VALIDITY', payload)
        },
        


    },
    mutations: {
        SET_VALIDITY(state, data) {
          
            state.valid = data;
        },
        

    }
}