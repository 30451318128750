/* eslint-disable */
import { createRouter, createWebHistory } from "vue-router";

import store from "@/store"; // Adjust the path to your store


const routes = [
  {
    path: "/",
    name: "home",
    meta: {
      auth: 0,
    },
    component: () => import("../views/LoginView.vue"),
  },
  {
    path: "/registration",
    name: "registration",
    meta: {
      auth: 0,
    },
    component: () => import("../views/RegistrationView.vue"),
  },
// ===================================== PROFILLING ==========================
  {
    path: "/HealthScreeningAndAssessment",
    name: "HealthScreeningAndAssessment",
    meta: {
      auth: 1,
    },
    component: () => import("../views/HealthScreeningAndAssessmentView.vue"),
  },

  {
    path: "/PatientProfile",
    name: "PatientProfile",
    meta: {
      auth: 1,
      parent: 'profilling',
      requiredQueryParameters: 1,
     
    },
    component: () => import("../components/HealthScreeningAndAssessment/ClientProfile.vue"),
  },

  {
    path: "/MedicalSurgicalHistory",
    name: "MedicalSurgicalHistory",
    meta: {
      auth: 1,
      parent: 'profilling',
      requiredQueryParameters: 1,
     
    },
    component: () => import("../components/HealthScreeningAndAssessment/MedicalSurgicalHistory.vue"),
  },

  {
    path: "/FamilyPersonalHistory",
    name: "FamilyPersonalHistory",
    meta: {
      auth: 1,
      parent: 'profilling',
      requiredQueryParameters: 1,
     
    },
    component: () => import("../components/HealthScreeningAndAssessment/FamilyPersonalHistory.vue"),
  },

  {
    path: "/LaboratoryImagingResults",
    name: "LaboratoryImagingResults",
    meta: {
      auth: 1,
      parent: 'profilling',
      requiredQueryParameters: 1,
     
    },
    component: () => import("../components/HealthScreeningAndAssessment/LaboratoryImagingResults.vue"),
  },
  {
    path: "/Immunizations",
    name: "Immunizations",
    meta: {
      auth: 1,
      parent: 'profilling',
      requiredQueryParameters: 1,
     
    },
    component: () => import("../components/HealthScreeningAndAssessment/ImmuniZation.vue"),
  },
  {
    path: "/OBGyneHistory",
    name: "OBGyneHistory",
    meta: {
      auth: 1,
      parent: 'profilling',
      requiredQueryParameters: 1,
     
    },
    component: () => import("../components/HealthScreeningAndAssessment/ObGyneHistory.vue"),
  },
  
  {
    path: "/PertinentPhysicalExaminationFindings",
    name: "PertinentPhysicalExaminationFindings",
    meta: {
      auth: 1,
      parent: 'profilling',
      requiredQueryParameters: 1,
     
    },
    component: () => import("../components/HealthScreeningAndAssessment/PertinentPhysicalExaminationFindings.vue"),
  },

  {
    path: "/NCDHighRiskAssessment",
    name: "NCDHighRiskAssessment",
    meta: {
      auth: 1,
      parent: 'profilling',
      requiredQueryParameters: 1,
     
    },
    component: () => import("../components/HealthScreeningAndAssessment/NCDHighRiskAssessment.vue"),
  },
  
  /*
  {
    path: "/AddEditHealthScreeningAndAssessment",
    name: "AddEditHealthScreeningAndAssessment",
    meta: {
      auth: 1,
    },
    component: () =>
      import(
        "../components/HealthScreeningAndAssessment/AddEditHealthScreeningAndAssessment.vue"
      ),
  },
  */

  // ===================================== PROFILLING ==========================

  {
    path: "/memberregistration",
    name: "memberregistration",
    meta: {
      auth: 1,
    },
    component: () => import("../views/MemberRegistrationView.vue"),
  },
  {
    path: "/AddEditMemberRegistration",
    name: "AddEditMemberRegistration",
    meta: {
      auth: 1,
    },
    component: () =>
      import("../components/MemberRegistration/AddEditMemberRegistration.vue"),
  },

  {
    path: "/dashboard",
    name: "dashboard",
    meta: {
      auth: 1,
    },
    component: () => import("../views/HomeView.vue"),
  },
  // ===================================== CONSULTATION ==========================
  {
    path: "/Consultation",
    name: "Consultation",
    meta: {
      auth: 1,
    },
    component: () => import("../views/ConsultationView.vue"),
  },
  {
    path: "/ConsClientInformation",
    name: "ConsClientInformation",
    meta: {
      auth: 1,
      parent: 'consultation',
      requiredQueryParameters: 1,
     
    },
    component: () => import("../components/ConsultationEntry/ConsClientInformation.vue"),
  },

  {
    path: "/ConsSubjectiveHistoryOfIlleness",
    name: "ConsSubjectiveHistoryOfIlleness",
    meta: {
      auth: 1,
      parent: 'consultation',
      requiredQueryParameters: 1,
     
    },
    component: () => import("../components/ConsultationEntry/ConsSubjectiveHistoryOfIlleness.vue"),
  },

  {
    path: "/ConsPhysicalExaminationFindings",
    name: "ConsPhysicalExaminationFindings",
    meta: {
      auth: 1,
      parent: 'consultation',
      requiredQueryParameters: 1,
     
    },
    component: () => import("../components/ConsultationEntry/ConsPhysicalExaminationFindings.vue"),
  },
  {
    path: "/ConsAssessmentAndDiagnosisResult",
    name: "ConsAssessmentAndDiagnosisResult",
    meta: {
      auth: 1,
      parent: 'consultation',
      requiredQueryParameters: 1,
     
    },
    component: () => import("../components/ConsultationEntry/ConsAssessmentAndDiagnosisResult.vue"),
  },

  {
    path: "/PlanManagement",
    name: "PlanManagement",
    meta: {
      auth: 1,
      parent: 'consultation',
      requiredQueryParameters: 1,
     
    },
    component: () => import("../components/ConsultationEntry/PlanManagement.vue"),
  },
  {
    path: "/ConsMedicineDispense",
    name: "ConsMedicineDispense",
    meta: {
      auth: 1,
      parent: 'consultation',
      requiredQueryParameters: 1,
     
    },
    component: () => import("../components/ConsultationEntry/ConsMedicineDispense.vue"),
  },
  
  /*
  {
    path: "/ConsultationEntry",
    name: "ConsultationEntry",
    meta: {
      auth: 1,
    },
    component: () =>
      import("../components/ConsultationEntry/ConsultationEntryView.vue"),
  },
  */
  // ===================================== CONSULTATION ==========================
  {
    path: "/Eprescription",
    name: "Eprescription",
    meta: {
      auth: 1,
    },
    component: () => import("../views/EpresView.vue"),
  },
  {
    path: "/Ekonsavslip",
    name: "Ekonsavslip",
    meta: {
      auth: 1,
    },
    component: () => import("../views/EkasView.vue"),
  },
  {
    path: "/Doctorslist",
    name: "Doctorslist",
    meta: {
      auth: 1,
    },
    component: () => import("../views/DoctorsListView.vue"),
  },
  {
    path: "/DataPrivacy",
    name: "DataPrivacy",
    meta: {
      auth: 1,
    },
    component: () => import("../views/DataPrivacyView.vue"),
  },

  {
    path: "/about",
    name: "about",
    meta: {
      auth: 1,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
let checkqparamsprofillingLocalStorage = () => {
  const profiling = JSON.parse(localStorage.getItem('qparamsprofilling'));

  if (profiling) {

    if (Object.keys(profiling).length > 0) {
      let validate = 0;
      if (profiling.hasOwnProperty("caseNo")) {
        validate++;
      }
      if (profiling.hasOwnProperty("dt")) {
        validate++;
      }
      return validate;
    } else {
      localStorage.removeItem('qparamsprofilling');
      return 0;
    }
  } else {
    localStorage.removeItem('qparamsprofilling');
    return 0;
  }
}

let checkqparamsconsultationLocalStorage = () => {
  const profiling = JSON.parse(localStorage.getItem('qparamsconsultation'));

  if (profiling) {

    if (Object.keys(profiling).length > 0) {
      let validate = 0;
      if (profiling.hasOwnProperty("caseNo")) {
        validate++;
      }
      if (profiling.hasOwnProperty("transNo")) {
        validate++;
      }
      return validate;
    } else {
      localStorage.removeItem('qparamsconsultation');
      return 0;
    }
  } else {
    localStorage.removeItem('qparamsconsultation');
    return 0;
  }
}



router.beforeEach((to, from, next) => {
  store.dispatch("setCurrentRouteName",to.name);
  if (localStorage.getItem("ua")) {
   
   if(to.meta.auth == 1){
    store
      .dispatch("checkAuth")
      .then((resp) => {
        if(to.meta.parent == "profilling" && to.meta.requiredQueryParameters == 1){
          let queryparams = JSON.parse(localStorage.getItem('qparamsprofilling'));
          
          switch (checkqparamsprofillingLocalStorage()) {
            case 1:
                if(JSON.stringify(to.query) != JSON.stringify(queryparams)){
                  next({ name: 'PatientProfile', query: queryparams });
                }else{
                  next();
                }
                
              break;
              case 2:
              
                if(JSON.stringify(to.query) != JSON.stringify(queryparams)){
                  next({ name: to.name, query: queryparams });
                }else{
                  next();
                }
                
              break;
            default:
              next({ name: 'HealthScreeningAndAssessment' });
              break;
          }
        }else if(to.meta.parent == "consultation" && to.meta.requiredQueryParameters == 1){  
          let queryparams = JSON.parse(localStorage.getItem('qparamsconsultation'));
          
          switch (checkqparamsconsultationLocalStorage()) {
            case 1:
                if(JSON.stringify(to.query) != JSON.stringify(queryparams)){
                  next({ name: 'ConsClientInformation', query: queryparams });
                }else{
                  next();
                }
                
              break;
              case 2:
              
                if(JSON.stringify(to.query) != JSON.stringify(queryparams)){
                  next({ name: to.name, query: queryparams });
                }else{
                  next();
                }
                
              break;
            default:
              next({ name: 'Consultation' });
              break;
          }
        }else{
          next();
        }
        
        
      }).catch((err) => {
        if(err){
          if (err.response) {
            if (err.response.status == 401) {
              localStorage.removeItem('ua')
              if(to.name != 'home'){
                next({ name: 'home' });
              }
            }
          }
        }
       
      });
   }else{
     next({ name: 'dashboard' });
   }
    
  } else {
    if (to.meta.auth == 0) {
      next();
    } else {
      next({ name: "home" });
    }
  }
  
});

export default router;
