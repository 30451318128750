// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Vuetify
import { createVuetify } from 'vuetify'

export default createVuetify({
  theme: {
    typography: {
      fontFamily: 'Roboto, sans-serif', // Replace with your Google Font
    },
  },
}
  // https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
  
)
