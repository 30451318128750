/* eslint-disable */
import axios from 'axios';
export default {

    state: {
        isAuth: false,
        user: [],
    },
    getters: {
        getAuthStatus(state) {
            return state.isAuth;
        },
        getCurrentUser(state) {
            return state.user;
        },
    },
    actions: {

        signOut({ commit }) {
            return new Promise((resolve, reject) => {

                axios({
                    method: "post",
                    url: process.env.VUE_APP_API + "auth/logout",
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("ua")
                    },

                })
                    .then((resp) => {
                        localStorage.removeItem("ua");
                        commit('REMOVE_USER', resp.data);
                        resolve("1");
                    })
                    .catch((err) => {
                        localStorage.removeItem("ua");
                        reject("0");
                    })
            })
        },
        checkAuth({ commit }) {
            return new Promise((resolve, reject) => {
              
                if (localStorage.getItem("ua") != null) {
                  
                    axios({
                        method: "post",
                        url: process.env.VUE_APP_API + "auth/me",
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("ua")
                        },
                    })
                        .then((resp) => {
                            commit('SET_USER', resp.data);
                            resolve(resp.data);

                        })
                        .catch((err) => {
                           
                            reject(err);
                        })
                } else {
                    commit('REMOVE_USER', false);
                    reject(false);
                }
            })
        },

        


    },
    mutations: {
        SET_USER(state, data) {
            state.isAuth = true;
            state.user = data;

        },
        REMOVE_USER(state) {
            state.isAuth = false;
            state.user = [];

        },

    }
}