<template>
   <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      :color="snackbarColor"
      rounded="pill"
    >
      {{ text }}

      <template v-slot:actions>
        <v-btn
          color="white"
          variant="text"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
</template>

<script>

export default {
  name: 'MySnackBar',

  data: () => ({
    snackbar: false,
    text: '',
    timeout: 5000,
    snackbarColor: ''
  }),
  methods: {
    showInformationMessage(message){
      this.snackbarColor = 'light-blue'
      this.text = message
      this.snackbar = true
    },
    showSuccessMessage(message){
      this.snackbarColor = 'green-darken-4'
      this.text = message
      this.snackbar = true
    },
    showWarningMessage(message){
      this.snackbarColor ='orange-darken-4'
      this.text = message
      this.snackbar = true
    },
    showErrorMessage(message){
      this.snackbarColor = 'red-darken-4'
      this.text = message
      this.snackbar = true
    }
  }
}
</script>
