/* eslint-disable */
import axios from 'axios';
export default {

    state: {
       pxEnlistment: [
        {
            PX_SEX: 'M',
            age: 18,
            fullage: 18,
        }
       ],
       pxSoap: {
        CASE_NO: '',
        TRANS_NO: '',

       },
       isDiabetic: false,
    },
    getters: {
        getPxEnlistment(state) {
            return state.pxEnlistment;
        },
        getDiabetic(state){
            return state.isDiabetic;
        },

        getSoap(state){
            return state.pxSoap;
        }
       
    },
    actions: {
        fetchPxifDiabetic({ commit }, diabetic){
            commit('setPxDiabetic', diabetic);
        },
        FetchPatientEnlistment({ commit }, case_no) {
            return new Promise((resolve, reject) => {

                axios({
                    method: "get",
                    url: process.env.VUE_APP_API + "hsa/FetchPatientEnlistment",
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("ua")
                    },
                    params: {
                        case_no: case_no
                    }

                })
                    .then((resp) => {
                        
                        commit('setPxEnlistment', resp.data);
                        resolve(resp);
                        
                    })
                    .catch((err) => {
                      
                        reject(err);
                    })
            })
        },
        
        FetchPatientSoap({ commit }, payload) {
            return new Promise((resolve, reject) => {

                axios({
                    method: "get",
                    url: process.env.VUE_APP_API + "soap/getPatientSOAP",
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("ua")
                    },
                    params: payload

                })
                    .then((resp) => {
                        
                        commit('setPxSoap', resp.data);
                        resolve(resp);
                        
                    })
                    .catch((err) => {
                      
                        reject(err);
                    })
            })
        },

    },
    mutations: {
        setPxEnlistment(state, data) {
            state.pxEnlistment = data;

        },
        setPxDiabetic(state, data){
          
            state.isDiabetic = data;
        },
        setPxSoap(state, data){
            state.pxSoap = data;
        }
        

    }
}