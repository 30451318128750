import { createStore } from 'vuex'
import auth from './modules/auth'
import validation from './modules/validation'
import patientInformation from './modules/patientInformation'
import utils from './modules/utils'
export default createStore({
 
  modules: {
    auth,
    validation,
    patientInformation,
    utils
  }
})
